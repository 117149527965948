import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"

export const useTransactionsStore = defineStore("transactions", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 100,
        current_page: 1,
        filters: [],
        currentItem: {},
        newUser: {},
        CurrentItemModal: false,
        transactionModal: false,
        UpdateItem: false,
        apiPlurar: 'transactions',
        apiSingle: 'transaction',
        sort_column: 'id',
        sort_direction: 'desc'

    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
    },
    actions: {
        PutItem() {
            this.UpdateItem = true
            let itemId = this.currentItem.id
            let data = {...this.currentItem}
            axios.put(process.env.VUE_APP_API_URL + '/admin-panal/' + this.apiPlurar + '/' + itemId, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        fetchItem(e) {
            axios.get(process.env.VUE_APP_API_URL + '/admin-panal/' + this.apiPlurar + '/' + e, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                 // let item = res.data.result[this.apiSingle];
                let item = res.data.result.user_subscription
                this.currentItem = item
            }).catch(error => {
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        async fetchList() {
            this.loading = true
            let apiurl = process.env.VUE_APP_API_URL + '/admin-panal/' + this.apiPlurar
            await axios.get(apiurl, {
                params: {
                    page: this.current_page,
                    per_page: this.per_page,
                    filters: this.filters
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
               /* this.ItemsList = res.data.result[this.apiPlurar].data
                this.ItemsInfo = res.data.result[this.apiPlurar]
                this.totalItems = res.data.result[this.apiPlurar].total
                this.current_page = res.data.result[this.apiPlurar].current_page
                this.per_page = res.data.result[this.apiPlurar].per_page*/
                this.ItemsList = res.data.result.subscriptions.data
                this.ItemsInfo = res.data.result.subscriptions
                this.totalItems = res.data.result.subscriptions.total
                this.current_page = res.data.result.subscriptions.current_page
                this.per_page = res.data.result.subscriptions.per_page


                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
    },

});
