// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"

export const useCarCategoriesStore = defineStore("carCategories", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 400,
        current_page: 1,
        filters: [],
        currentItem: {},
        newItem: {},
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar: 'categories',
        apiSingle: 'item',

    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
    },
    actions: {
        CreateNewItem() {
            this.UpdateItem = true
            let data = this.newItem

            axios.post(process.env.VUE_APP_API_URL + '/admin-panal/' + this.apiPlurar, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                this.ItemsList.unshift(res.data.result[this.apiSingle])
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        DeleteItem(i,itemId) {
            axios.delete(process.env.VUE_APP_API_URL + '/admin-panal/' + this.apiPlurar + '/' + itemId, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.ItemsList.splice(i,1)
                Swal.fire("Success!", res.data.message, "success");
                this.fetchList()
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        PutItem() {
            this.UpdateItem = true
            let itemId = this.currentItem.id
            let data = this.currentItem
            axios.put(process.env.VUE_APP_API_URL + '/admin-panal/' + this.apiPlurar + '/' + itemId, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                this.ItemsList.find(x=>x.id === itemId).name = this.currentItem.name
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        fetchItem(e) {
            axios.get(process.env.VUE_APP_API_URL + '/admin-panal/' + this.apiPlurar + '/' + e, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                let item = res.data.result[this.apiSingle];
                this.currentItem = item
            }).catch(error => {
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        async fetchList() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/admin-panal/categories', {
                params: {
                    page: this.current_page,
                    per_page: this.per_page,
                    filters: this.filters
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                this.ItemsList = res.data.result[this.apiPlurar].data
                this.ItemsInfo = res.data.result[this.apiPlurar]

                this.totalItems = res.data.result[this.apiPlurar].total
                this.current_page = res.data.result[this.apiPlurar].current_page
                this.per_page = res.data.result[this.apiPlurar].per_page
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
    },

});
