// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"

export const useGlobalStore = defineStore("global", {
    state: () => ({
        paymentMethods:[],
        subscriptionsStatus:[],
        adminRoles:[],
        adminList:[],
    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
    },
    actions: {
        async admins() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/core/common/list/admins', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                this.adminList = res.data.result.list
            }).catch(error => {
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
        async fetchAdminRoles() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/core/common/list/admin-roles', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
              /*  this.adminRoles = res.data.result.list.map(x=>{
                    return x.id
                })*/
                this.adminRoles = res.data.result.list
            }).catch(error => {
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
        async fetchPaymentMethods() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/core/common/list/subscription-payment-method', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                this.paymentMethods = res.data.result.list.map(x=>{
                    return x.id
                })
            }).catch(error => {
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
        async fetchSubscriptionsStatus() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/core/common/list/subscription-status', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                this.subscriptionsStatus = res.data.result.list.map(x=>{
                    return x.id
                })
            }).catch(error => {
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
    },

});
