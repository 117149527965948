import { useLayoutStore } from "./pinia/layout";
import { useAuthFakeStore } from "./pinia/authFake";
import { useAuthStore } from "./pinia/auth";
import { useNotificationStore } from "./pinia/notification";
import { useCarsStore } from "./pinia/cars";
import { useRolesStore } from "./pinia/roles";
import { useCompaniesStore } from "./pinia/companies";
import { useSubscriptionsStore } from "./pinia/subscriptions";
import { useGlobalStore } from "./pinia/global";
import { useTransactionsStore } from "./pinia/transactions";
import { useCarCategoriesStore } from "./pinia/carcategories";
import { useCarGearBoxStore } from "./pinia/gearbox";
import { useUsersStore } from "./pinia/users"
import { useCarKarossStore } from "./pinia/kaross"
import { useCarDrivningStore } from "./pinia/drivning"
import { useCarBrandStore } from "./pinia/carbrands"
import { useCarBrandModelStore } from "./pinia/carbrandsModel"
import { useCarBrandVersionStore } from "./pinia/carbrandsVersion"
import { useCarEquipmentsStore } from "./pinia/CarEquipments"
import { useVatStore } from "./pinia/vat"
import { createPinia } from "pinia";

const pinia = createPinia();
export default pinia;

export { useCarEquipmentsStore,useVatStore,useCarBrandVersionStore,useCarBrandModelStore,useCarBrandStore,useCarDrivningStore,useCarKarossStore,useCarGearBoxStore,useUsersStore,useTransactionsStore,useGlobalStore,useCarCategoriesStore,useLayoutStore, useAuthFakeStore, useAuthStore, useNotificationStore,useCarsStore,useRolesStore,useCompaniesStore,useSubscriptionsStore };
