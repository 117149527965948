import {defineStore} from "pinia";
import {getFirebaseBackend} from '../../authUtils'
import axios from "axios";
import {Items} from "@/components/menu";

function getParsedCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const useAuthStore = defineStore("auth", {
    state: () => ({
        currentUser: getParsedCookie('stammeruser'),
        currentLoggedUser: {},
        menuItems: []
    }),
    computed: {},
    getters: {
        loggedIn() {
            return !!this.currentUser
        }
    }
    ,
    actions: {



        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },


        logIn({email, password}) {
            return getFirebaseBackend().loginUser(email, password).then(() => {
                this.validate()
            })
        },
        getUser() {
            let tokenId = getParsedCookie('stammeruser')
            let token = tokenId


            console.log('token', this.$user)
            if (token !== undefined && token !== null) {
                axios.get(process.env.VUE_APP_API_URL + "/admin-panal/auth/me", {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
                }).then(res => {
                    this.currentLoggedUser = res.data.result.user
                    const userRole = this.currentLoggedUser.role
                    if (userRole !== undefined) {
                        /*this.menuItems = Items.filter(item => {
                            if (userRole.includes(item.permissions)) {
                                return item;
                            }
                            return item.subItems && item.subItems.map(subItem => {
                                if (userRole.includes(subItem.permissions)) {
                                    return subItem;
                                }
                            });
                        }).filter(item => item !== undefined);*/
                        this.menuItems = Items
                    }
                }).catch(error => {
                    document.cookie = 'stammeruser=;max-age=0; path=/';
                    // window.location.href = "/"
                })
            }

        },

        setUser(user) {
            this.currentUser = user
            this.saveState('stammeruser', user)


        },
        saveState(key, state) {
            this.setCookie(key, state.token, 7)
        },


        logOut() {
            this.setUser(null)
            return new Promise((resolve, reject) => {
                getFirebaseBackend().logout().then(() => {
                    resolve(true);
                }).catch((error) => {
                    reject(this._handleError(error));
                })
            });
        },
    },
})
