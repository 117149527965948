// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"

export const useCarBrandVersionStore = defineStore("carbrandsVersion", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 10000,
        current_page: 1,
        filters: {name:''},
        currentItem: {},
        newItem: {},
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar: 'car-versions',
        apiSingle: 'item',


    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
    },
    actions: {
        CreateNewItem() {

            if(this.newItem.car_model !== undefined){
                this.UpdateItem = true
                let data = {name:this.newItem.name,car_model_id:this.newItem.car_model.id}

                axios.post(process.env.VUE_APP_API_URL + '/admin-panal/' + this.apiPlurar, data, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + this.token,
                        }
                    }
                ).then(res => {
                    this.UpdateItem = false
                    this.ItemsList.unshift(res.data.result[this.apiSingle])
                    Swal.fire("Success!", res.data.message, "success");
                }).catch(error => {
                    this.UpdateItem = false
                    Swal.fire("Error!", error.response.data.message, "error");
                })
            }else{
                Swal.fire("Error!",'Modell required', "error");
            }

        },
        DeleteItem(i,itemId) {
            axios.delete(process.env.VUE_APP_API_URL + '/admin-panal/' + this.apiPlurar + '/' + itemId, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.ItemsList.splice(i,1)
                Swal.fire("Success!", res.data.message, "success");
                this.fetchList()
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        PutItem() {
            this.UpdateItem = true
            let itemId = this.currentItem.id
            let data = this.currentItem
             data.car_model_id = this.currentItem.car_model.id
            axios.put(process.env.VUE_APP_API_URL + '/admin-panal/' + this.apiPlurar + '/' + itemId, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                this.ItemsList.find(x=>x.id === itemId).name = this.currentItem.name
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        fetchItem(e) {
            axios.get(process.env.VUE_APP_API_URL + '/admin-panal/' + this.apiPlurar + '/' + e, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                let item = res.data.result.item;
                this.currentItem = item
            }).catch(error => {
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        async fetchList() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/admin-panal/'+ this.apiPlurar, {
                params: {
                    page: this.current_page,
                    per_page: this.per_page,
                    filters: this.filters
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                this.ItemsList = res.data.result.car_versions.data
                this.ItemsInfo = res.data.result.car_versions

                this.totalItems = res.data.result.car_versions.total
                this.current_page = res.data.result.car_versions.current_page
                this.per_page = res.data.result.car_versions.per_page
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
    },

});
