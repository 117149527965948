// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"

export const useUsersStore = defineStore("users", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 1000,
        current_page: 1,
        filters: [],
        currentItem: {},
        newUser: {},
        CurrentItemModal: false,
        UpdateItem: false,
        apiPlurar:'admins',
        apiSingle:'admin',
        sort_column:'id',
        sort_direction:'desc'

    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
    },
    actions: {
        CreateNewItem() {
            this.UpdateItem = true
            let data = {...this.newUser}
            data.role = this.newUser.role.map(x=>{
                return x.id
            })
            data.active = this.newUser ? '1' : '0'

            axios.post(process.env.VUE_APP_API_URL + '/admin-panal/'+this.apiPlurar, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                this.ItemsList.push(res.data.result[this.apiSingle])
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        PutItem() {
            this.UpdateItem = true
            let itemId = this.currentItem.id
            let data = {...this.currentItem}
            data.active = this.currentItem.active ? '1' : '0'
            data.role = this.currentItem.role.map(x=>{
                return x.id
            })

            axios.put(process.env.VUE_APP_API_URL + '/admin-panal/'+this.apiPlurar+'/' + itemId, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                this.currentItem.active = Boolean(parseInt(res.data.result[this.apiSingle].active))
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        ResetUserPass() {
            this.UpdateItem = true
            let itemId = this.currentItem.id

            axios.get(process.env.VUE_APP_API_URL + '/admin-panal/'+this.apiPlurar+'/reset-password/' + itemId, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false

                Swal.fire("Reset Success!", res.data.result.new_password, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        fetchItem(e) {
            axios.get(process.env.VUE_APP_API_URL + '/admin-panal/'+this.apiPlurar+'/' + e, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                res.data.result[this.apiSingle].active = Boolean(parseInt(res.data.result[this.apiSingle].active))
                let item = res.data.result[this.apiSingle];
                this.currentItem = item
            }).catch(error => {
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        async fetchList() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/admin-panal/'+this.apiPlurar, {
                params: {
                    page: this.current_page,
                    per_page: this.per_page,
                    filters: this.filters
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                this.ItemsList = res.data.result[this.apiPlurar].data
                this.ItemsInfo = res.data.result[this.apiPlurar]

                this.totalItems = res.data.result[this.apiPlurar].total
                this.current_page = res.data.result[this.apiPlurar].current_page
                this.per_page = res.data.result[this.apiPlurar].per_page
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
    },

});
