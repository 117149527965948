// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"

export const useCompaniesStore = defineStore("companies", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 100,
        current_page: 1,
        filters: {users:{name:''}},
        currentItem: {
            name: '',
            email: '',
            tax_no: '',
            auto_renew: '',
            active: '',
            vat: [],
            commercial_registration_no: '',
            count_branches: '1',
        },
        CurrentItemModal: false,
        UpdateItem: false,
    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
    },
    actions: {
        CreateNewItem() {
            this.UpdateItem = true
            axios.post(process.env.VUE_APP_API_URL + '/admin-panal/companies', {
                    name: this.currentItem.name,
                    email: this.currentItem.email,
                    tax_no: this.currentItem.tax_no,
                    auto_renew: Number(this.currentItem.auto_renew),
                    active: Number(this.currentItem.active),
                    commercial_registration_no: this.currentItem.commercial_registration_no,
                    count_branches: this.currentItem.count_branches,
                    vendor_id: this.currentItem.vendor.id,
                address:this.currentItem.address,
                city:this.currentItem.city,
                zipcod:this.currentItem.zipcod,
                company_email:this.currentItem.company_email,
                    vat: this.currentItem.vat.map(x=>{return x.id}),
                }, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                this.ItemsList.push(res.data.result.company)
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        ResetUserPass() {
            this.UpdateItem = true
            let itemId = this.currentItem.id
            axios.get(process.env.VUE_APP_API_URL + '/admin-panal/companies/reset-password/' + itemId, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                Swal.fire("Reset Success!", res.data.result.new_password, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        PutItem() {
            this.UpdateItem = true
            let itemId = this.currentItem.id
            axios.put(process.env.VUE_APP_API_URL + '/admin-panal/companies/' + itemId, {
                    name: this.currentItem.name,
                    email: this.currentItem.email,
                    tax_no: this.currentItem.tax_no,
                    auto_renew: Number(this.currentItem.auto_renew),
                    active: Number(this.currentItem.active),
                    commercial_registration_no: this.currentItem.commercial_registration_no,
                    count_branches: this.currentItem.count_branches,
                    vendor_id: this.currentItem.vendor.id,
                    has_fortnox: this.currentItem.has_fortnox ? '1' : '0',
                    has_scrive: this.currentItem.has_fortnox ? '1' : '0',
                    has_blocket: this.currentItem.has_fortnox ? '1' : '0',
                    vat: this.currentItem.vat_method.map(x=>{return x.id}),
                   address:this.currentItem.address,
                   city:this.currentItem.city,
                   zipcod:this.currentItem.zipcod,
                   company_email:this.currentItem.company_email


                }, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                        'Accept-Language': 'sv'
                    }
                }
            ).then(res => {
                this.UpdateItem = false
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        fetchItem(e) {
            axios.get(process.env.VUE_APP_API_URL + '/admin-panal/companies/' + e, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                res.data.result.company.active = Boolean(parseInt(res.data.result.company.active))
                res.data.result.company.has_fortnox = Boolean(parseInt(res.data.result.company.has_fortnox))
                res.data.result.company.has_scrive = Boolean(parseInt(res.data.result.company.has_scrive))
                res.data.result.company.has_blocket = Boolean(parseInt(res.data.result.company.has_blocket))
                res.data.result.company.auto_renew = Boolean(parseInt(res.data.result.company.auto_renew))
                let item = res.data.result.company;
                this.currentItem = item
            }).catch(error => {
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        async fetchList() {
            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/admin-panal/companies', {
                params: {
                    page: this.current_page,
                    per_page: this.per_page,
                    filters: this.filters
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                this.ItemsList = res.data.result.companies.data
                this.ItemsInfo = res.data.result.companies

                this.totalItems = res.data.result.companies.total
                this.current_page = res.data.result.companies.current_page
                this.per_page = res.data.result.companies.per_page
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
    },

});
