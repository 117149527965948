// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useRolesStore = defineStore("roles", {
    state: () => ({
        serverItems: [],
        loading: true,
        totalItems: 0,
        RolesList: [],
        itemsPerPage: 1000,
        currentPage: 1,
        CurrentRole: {},
        CurrentRoleModal: false,
        PermissionsList: [],
        UpdateRoleState: false,
    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
        PermissionsListGetter: (state) => {
            let permissions = state.CurrentRole.permissions;
            if(Object.keys(state.CurrentRole).length > 0){
                const permissionIdsMap = permissions.map(permission => {
                    return permission.id;
                });
                const filteredPermissionsList = state.PermissionsList.filter(permission =>
                    !permissionIdsMap.includes(permission.id)
                );
                return filteredPermissionsList;
            }else{
                return state.PermissionsList
            }
        },
    },
    actions: {
        CreateNewRole(payload){
            this.UpdateRoleState = true
           axios.post(process.env.VUE_APP_API_URL + '/admin-panal/roles', {
               permissions:payload,
               display_name:this.CurrentRole.display_name,
               name:this.CurrentRole.name
               } ,{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
               this.UpdateRoleState = false
               this.RolesList.push(res.data.result.data)
            }).catch(error => {
               this.UpdateRoleState = false
               Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        UpdateRole(payload,roleId){
            this.UpdateRoleState = true
           axios.put(process.env.VUE_APP_API_URL + '/admin-panal/roles/'+roleId, {
               permissions:payload,
               display_name:this.CurrentRole.display_name
               } ,{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
               this.UpdateRoleState = false
            }).catch(error => {
               this.UpdateRoleState = false
               Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        deleteRow(roleId){
           axios.delete(process.env.VUE_APP_API_URL + '/admin-panal/roles/'+roleId ,{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                let role = this.RolesList.findIndex(x=>x.id == roleId)
               this.RolesList.splice(role,1)
               Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }).catch(error => {
               Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        fetchPermissions() {
            axios.get(process.env.VUE_APP_API_URL + '/core/common/list/permissions', {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.PermissionsList = res.data.result.list
            }).catch(error => {
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");
            })

        },
        fetchRole(e) {
            axios.get(process.env.VUE_APP_API_URL + '/admin-panal/roles/' + e, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.CurrentRole = res.data.result.item
            }).catch(error => {
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");
            })

        },
        async fetchRoles() {

            this.loading = true
            await axios.get(process.env.VUE_APP_API_URL + '/admin-panal/roles?page=' + this.currentPage, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                console.log(res.data.result)
                this.RolesList = res.data.result.roles
                this.totalItems = res.data.result.roles.length
                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");

            })
            await this.fetchPermissions()
        },
    },

});
