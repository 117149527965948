<template>
 <router-view></router-view>
</template>

<script>
import { useAuthStore } from "@/state/pinia/auth";

export default {
  name: 'App',
  components: {
  },
  methods:{
    initAuth() {
      const auth = useAuthStore()
      auth.getUser()
    }
  },
  created() {
    this.initAuth()
  },
}
</script>

