import _ from 'lodash'

const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: "menuitems.cars.text",
        icon: "bxs-user-detail",
        subItems: [
            {
                id: 'cars' + 1,
                label: "menuitems.cars.list.all",
                link: "/cars/list/all",
                parentId: 2,
                permissions: ['SELLER', 'ADMIN']
            },
            {
                id: 'cars' + 2,
                label: "menuitems.cars.list.personal",
                link: "/cars/list/personal",
                parentId: 2,
                permissions: ['SELLER', 'ADMIN']
            },
            {
                id: 'cars' + 3,
                label: "menuitems.cars.list.transport",
                link: "/cars/list/transport",
                parentId: 2,
                permissions: ['SELLER', 'ADMIN']
            },
            {
                id: 'cars' + 4,
                label: "menuitems.cars.list.moped",
                link: "/cars/list/moped",
                parentId: 2,
                permissions: ['SELLER', 'ADMIN']
            },
            {
                id: 'cars' + 5,
                label: "menuitems.cars.list.trailer",
                link: "/cars/list/trailer",
                parentId: 2,
                permissions: ['SELLER', 'ADMIN']
            },
            {
                id: 'cars' + 6,
                label: "menuitems.cars.list.atv",
                link: "/cars/list/atv",
                parentId: 2,
                permissions: ['SELLER', 'ADMIN']
            },
            {
                id: 'cars' + 7,
                label: "menuitems.cars.list.lastmaskin",
                link: "/cars/list/lastmaskin",
                parentId: 2,
                permissions: ['SELLER', 'ADMIN']
            },
            {
                id: 'cars' + 8,
                label: "menuitems.cars.list.truck",
                link: "/cars/list/truck",
                parentId: 2,
                permissions: ['SELLER', 'ADMIN']
            },
        ]
    },
    {
        id: 4,
        label: "menuitems.invoices.text",
        icon: "bx-receipt",
        subItems: [
            {
                id: 'invoices' + 1,
                label: "menuitems.invoices.list.sellInvoice",
                link: "/invoices/list",
                parentId: 4,
                permissions: ['ADMIN']
            },
            {
                id: 'invoices' + 1,
                label: "menuitems.invoices.list.offer",
                link: "/invoices/list",
                parentId: 4,
                permissions: ['ADMIN']
            },
            {
                id: 'invoices' + 2,
                label: "menuitems.invoices.list.buyInvoice",
                link: "/invoices/detail",
                parentId: 4,
                permissions: ['ADMIN']
            }
        ]
    },
    {
        id: 7,
        label: "menuitems.ue.text",
        icon: "bx-receipt",
        subItems: [
            {
                id: 'ue' + 1,
                label: "menuitems.ue.list.todo",
                link: "/invoices/list",
                parentId: 7,
                permissions: ['ADMIN']
            },
            {
                id: 'ue' + 1,
                label: "menuitems.ue.list.list",
                link: "/invoices/details",
                parentId: 7,
                permissions: ['ADMIN']
            }
        ]
    },
    {
        id: 6,
        label: "menuitems.economy.text",
        icon: "bx-receipt",
        subItems: [
            {
                id: 'economy' + 1,
                label: "menuitems.economy.list.bills",
                link: "/invoices/list",
                parentId: 4,
                permissions: ['ADMIN']
            },
            {
                id: 'economy' + 2,
                label: "menuitems.economy.list.paid",
                link: "/invoices/list",
                parentId: 4,
                permissions: ['ADMIN']
            },
            {
                id: 'economy' + 3,
                label: "menuitems.economy.list.credit",
                link: "/invoices/list",
                parentId: 4,
                permissions: ['ADMIN']
            },
            {
                id: 'economy' + 4,
                label: "menuitems.economy.list.underlag",
                isTitle: true,
                link: "#",
                parentId: 4,
                permissions: ['ADMIN']
            },
            {
                id: 'economy' + 5,
                label: "menuitems.economy.list.underlag_all",
                link: "#",
                parentId: 4,
                permissions: ['ADMIN']
            },
            {
                id: 'economy' + 6,
                label: "menuitems.economy.list.underlag_finans",
                link: "#",
                parentId: 4,
                permissions: ['ADMIN']
            },
            {
                id: 'economy' + 6,
                label: "menuitems.economy.list.underlag_utbetalningar",
                link: "#",
                parentId: 4,
                permissions: ['ADMIN']
            },
            {
                id: 'economy' + 6,
                label: "menuitems.economy.list.underlag_cancelled",
                link: "#",
                parentId: 4,
                permissions: ['ADMIN']
            },
        ]
    },
    {
        id: 3,
        label: "menuitems.contacts.text",
        icon: "bxs-user-detail",
        subItems: [
            {
                id: 'contacts' + 1,
                label: "menuitems.contacts.list.persons",
                link: "/contacts/list",
                parentId: 3,
                permissions: ['SUBADMIN']
            },
            {
                id: 'contacts' + 2,
                label: "menuitems.contacts.list.companies",
                link: "/contacts/profile",
                parentId: 3,
                permissions: ['ADMIN']
            }
        ]
    },
    {
        id: 5,
        label: "menuitems.reports.text",
        icon: "bx-home-circle",
        subItems: [
            {
                id: 'reports' + 1,
                label: "menuitems.reports.list.cars",
                link: "/",
                parentId: 5,
                permissions: ['ADMIN']
            },
            {
                id: 'reports' + 2,
                label: "menuitems.reports.list.customers",
                link: "/dashboard/saas",
                parentId: 5,
                permissions: ['SUBADMIN']
            },
            {
                id: 'reports' + 3,
                label: "menuitems.reports.list.sellers",
                link: "/dashboard/crypto",
                parentId: 5,
                permissions: ['ADMIN']
            },
            {
                id: 'reports' + 4,
                label: "menuitems.reports.list.economy",
                link: "/dashboard/crypto",
                parentId: 5,
                permissions: ['ADMIN']
            }
        ]
    },
    {
        id: 6,
        label: "menuitems.users.text",
        icon: "bx-home-circle",
        link: "/users",
        permissions: ['SUBADMIN']
    },
    {
        id: 6,
        label: "Roles",
        icon: "bx-home-circle",
        link: "/settings/roles",
        permissions: ['SUBADMIN', 'ADMIN']
    },
    {
        id: 6,
        label: "menuitems.companies.list.subscriptions",
        icon: "bx-home-circle",
        link: "/subscriptions",
        permissions: ['ADMIN']
    },
    {
        id: 6,
        label: "menuitems.companies.list.transactions",
        icon: "bx-home-circle",
        link: "/transactions",
        permissions: ['ADMIN']
    },
    {
        id: 7,
        label: "menuitems.companies.text",
        icon: "bx-home-circle",
        link: "/companies",
        permissions: ['ADMIN', 'SUBADMIN']
    },
    {
        id: 8,
        label: "menuitems.global.tax",
        icon: "bx-home-circle",
        link: "/settings/vat",
        permissions: []
    },

    {
        id: 8,
        label: "menuitems.carsSettings.text",
        icon: "bx-home-circle",
        subItems: [
            {
                id: 'carsSettings' + 1,
                label: "menuitems.carsSettings.categories",
                link: "/settings/cars/categories",
                parentId: 8,
            },
            {
                id: 'carsSettings' + 2,
                label: "menuitems.carsSettings.brands",
                link: "/settings/cars/brands",
                parentId: 8,
            },
            {
                id: 'carsSettings' + 3,
                label: "menuitems.carsSettings.modell",
                link: "/settings/cars/modell",
                parentId: 8,
            },
            {
                id: 'carsSettings' + 4,
                label: "menuitems.carsSettings.version",
                link: "/settings/cars/version",
                parentId: 8,
            },
            {
                id: 'carsSettings' + 5,
                label: "menuitems.carsSettings.gearbox",
                link: "/settings/cars/gearbox",
                parentId: 8,
            },
            {
                id: 'carsSettings' + 6,
                label: "menuitems.carsSettings.kaross",
                link: "/settings/cars/kaross",
                parentId: 8,
            },
            {
                id: 'carsSettings' + 7,
                label: "menuitems.carsSettings.drivning",
                link: "/settings/cars/drivning",
                parentId: 8,
            },
            {
                id: 'carsSettings' + 8,
                label: "menuitems.carsSettings.equipments",
                link: "/settings/cars/equipments",
                parentId: 8,
            },
        ]
    },

];



export  {menuItems};

