import {defineStore} from "pinia";
import axios from "axios";
export const useCarsStore = defineStore("cars", {
    state: () => ({
        serverItems: [],
        loading: true,
        totalItems: 0,
        CarsList: [],
        itemsPerPage: 18,
        currentPage: 1,
        CurrentCar:{},
        activeProfileTab : 'gallery',
        CarServiceBook:[
            {date:'2022-03-23',meters:'2290km',desc:'heööp wpröds'}
        ]
    }),
    actions: {

        async fetchCars() {
            axios.get('https://dev-n18mizx69hiv1el.api.raw-labs.com/2').then(res => {
                if(this.CarsList.length === 0){
                    this.CarsList = res.data
                }else{
                    res.data.forEach(x=>{
                        this.CarsList.push(x)
                    })

                }
                this.totalItems = res.data.length
                this.loading = false
            })
        },
    },

});


