// roles.js
import {defineStore, createReactive} from "pinia";
import {useAuthStore, useCompaniesStore} from "@/state/pinia";
import axios from "axios";
import Swal from "sweetalert2";
import qs from "qs"
import moment from "moment";

export const useSubscriptionsStore = defineStore("subscriptions", {
    state: () => ({
        loading: true,
        totalItems: 0,
        ItemsList: [],
        ItemsInfo: {},
        per_page: 100,
        current_page: 1,
        busy: true,
        filtersSample:   {
            start_date:'',
            end_date:'',
            free_trial:'',
            renew:'',
            active:'',
            user_subscription_transactions:{
                status:'',
                payment_method:''
            },
        },
        start_date:null,
        end_date:null,
        filters:   {
            start_date:'',
            end_date:'',
            free_trial:'',
            renew:'',
            active:'',
            user_subscription_transactions:{
                status:'',
                payment_method:''
            },
        },
        currentItem: {
        },
        newItemSample:{
            start_date:moment(new Date()).format('YYYY-MM-DD'),
            end_date: moment(new Date()).add(1, 'years').format('YYYY-MM-DD'),
            renew: '1',
            active: '1',
            free_trial: '0',
            company_id:  null,
            user_subscription_transactions: [
                {
                    cost:'',
                    status:'PENDING',
                    payment_method:'TRANSFER'
                }
            ]
        },
        newItem:{
            start_date:moment(new Date()).format('YYYY-MM-DD'),
            end_date: moment(new Date()).add(1, 'years').format('YYYY-MM-DD'),
            renew: '1',
            active: '1',
            free_trial: '0',
            company_id:  null,
            user_subscription_transactions: [
                {
                    cost:'',
                    status:'PENDING',
                    payment_method:'TRANSFER'
                }
            ]
        },
        UpdateItem: false,
        sort_column:'active',
        sort_direction:'desc'
    }),
    getters: {
        token: () => {
            const authToken = useAuthStore()
            const token = authToken.currentUser;
            return token
        },
    },
    actions: {
        CreateNewItem() {
            this.UpdateItem = true
            axios.post(process.env.VUE_APP_API_URL + '/admin-panal/subscriptions', {
                start_date: this.newItem.start_date,
                end_date: this.newItem.end_date,
                renew: this.newItem.renew,
                active: this.newItem.active,
                free_trial: this.newItem.free_trial,
                company_id: this.newItem.company_id,
                user_subscription_transactions: this.newItem.user_subscription_transactions
                }, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                this.UpdateItem = false
                this.ItemsList.push(res.data.result.user_subscription)
                const comapny = useCompaniesStore()
                const currentCompany = comapny.currentItem;
                if(currentCompany.user_subscriptions !== undefined){
                    currentCompany.user_subscriptions.unshift(res.data.result.user_subscription)
                }
                Swal.fire("Success!", res.data.message, "success");
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        PutItem(payload) {
            this.UpdateItem = true
            let itemId = payload.id
            axios.put(process.env.VUE_APP_API_URL + '/admin-panal/subscriptions/' + itemId, payload, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + this.token,
                    }
                }
            ).then(res => {
                Swal.fire("Success!", res.data.message, "success");
                this.UpdateItem = false
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        getItem(payload) {
            this.UpdateItem = true
            axios.get(process.env.VUE_APP_API_URL + '/admin-panal/subscriptions/' + payload, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                }
                }
            ).then(res => {
                this.currentItem = res.data.result.user_subscription
                this.UpdateItem = false
            }).catch(error => {
                this.UpdateItem = false
                Swal.fire("Error!", error.response.data.message, "error");
            })
        },
        async fetchList() {
            this.busy = true
            await axios.get(process.env.VUE_APP_API_URL + '/admin-panal/subscriptions', {
                params: {
                    page: this.current_page,
                    per_page: this.per_page,
                    filters: this.filters,
                    sort_column:this.sort_column,
                    sort_direction:this.sort_direction,
                    start_date:this.start_date,
                    end_date:this.end_date
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.token,
                },
            }).then(res => {
                this.ItemsList = res.data.result.subscriptions.data
                this.ItemsInfo = res.data.result.subscriptions
                this.totalItems = res.data.result.subscriptions.total
                this.current_page = res.data.result.subscriptions.current_page
                this.per_page = res.data.result.subscriptions.per_page
                this.busy = false
            }).catch(error => {
                this.busy = false
                console.log(error)
                Swal.fire("Error!", error.response.data.message, "error");

            })
        },
    }

});
